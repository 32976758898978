<template>
	<!-- 用户中心 -->
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<span>{{$t('aside.list')[8]}}</span>
			</div>
			<!-- pc -->
			<div class="user_box" v-if="isSmall=='normal'">
				<div class="row_left">
					<div class="head"><i class="iconfont icon-usercenter"></i>{{$t('user.data')}}</div>
					<div class="form">
						<div class="item">
							<div class="title">{{$t('user.userName')}}:</div>
							<div class="text">{{account}}</div>
						</div>
						<div class="item">
							<div class="title">{{$t('user.lv')}}:</div>
							<div class="text">
								<div class="value">
									{{
										type==1?
										$t('level')[0]:
										type==2?
										$t('level')[1]:
										type==4?
										$t('level')[2]:
										type=='DEX'?
										$t('level')[3]:type
									}}
									（{{$t('common.endtime')}}：{{available_time}}）
								</div>
								<!-- <router-link tag="div" class="g_btn btn" to="/renew">{{$t('user.upORren')}}</router-link> -->
								<div class="g_btn btn" @click="tipTo">{{$t('user.upORren')}}</div>
							</div>
						</div>
						<!-- 自动续费 -->
						<div class="help">
							<div class="title">{{$t('user.autoRenew')}}:</div>
							<div class="text">
								<el-switch
                                @change="autoRenewChange"
								active-value="1"
								inactive-value="0"
								v-model="autoState"
								active-color="#E65B1B"
                                inactive-color="#e9e9e9">
								</el-switch>
							</div>
						</div>
						<div class="help">
							<div class="title">{{$t('user.help')}}:</div>
							<div class="text">
								<el-switch
                                @change="helpChange"
								active-value="1"
								inactive-value="0"
								v-model="helpState"
								active-color="#E65B1B"
                                inactive-color="#e9e9e9">
								</el-switch>
							</div>
						</div>
					</div>
				</div>
				
				<div class="row_right">
					<div class="head"><i class="iconfont icon-safe"></i>{{$t('user.security')}}</div>
					<div class="form">
						 <!-- v-if="contact_type==1" -->
						<div class="item">
							<div class="title">{{$t('common.telegram')}}:</div>
							<div class="text">
								<div class="value">{{telegram?'@'+telegram:telegram}}</div>
								<span class="g_btn btn" @click="bindTelegram">{{telegram?$t('common.remove'):$t('common.bind')}}</span>
							</div>
						</div>
						<div class="item">
							<div class="title">{{$t('common.email')}}:</div>
							<div class="text">
								<div class="value">{{email}}</div>
								<span class="g_btn btn" @click="showEmail = true">{{email?$t('common.modify'):$t('common.bind')}}</span>
							</div>
						</div>
						<div class="item">
							<div class="title">{{$t('common.pwd')}}:</div>
							<div class="text">
								<div class="value">**************</div>
								<span class="g_btn btn" @click="openPwdPop">{{$t('common.modify')}}</span>
							</div>
						</div>
						<div class="safeLogin">
							<div class="title">{{$t('user.safeLogin')}}:</div>
							<div class="text">
								<el-switch
                                @change="switchChange"
								active-value="1"
								inactive-value="0"
								v-model="safeType"
								active-color="#E65B1B"
                                inactive-color="#e9e9e9">
								</el-switch>
							</div>
						</div>
						<!-- <div class="ipVer">
							<div class="title">
								{{$t('user.ipVer')}}:<span>({{$t('user.ipRecommend')}})</span>
							</div>
							<div class="text">
								<el-switch
                                @change="switchChange2"
								active-value="1"
								inactive-value="0"
								v-model="ipVer"
								active-color="#E65B1B"
                                inactive-color="#e9e9e9">
								</el-switch>
							</div>
						</div> -->
					</div>
				</div>
			</div>
			<!-- 移动适配 -->
			<div class="user_box tab_box" v-else-if="isSmall=='small'">

				<div class="tab_list">
					<div class="item" :class="{active:selectTab=='user'}" @click="switchTab('user')"><i class="iconfont icon-usercenter"></i>{{$t('user.data')}}</div>
					<div class="item" :class="{active:selectTab=='safe'}" @click="switchTab('safe')"><i class="iconfont icon-safe"></i>{{$t('user.security')}}</div>
					<div class="item" :class="{active:selectTab=='lang'}" @click="switchTab('lang')"><i class="iconfont icon-lang"></i>{{$t('user.language')}}</div>
				</div>
					
				<div class="row_left" v-show="selectTab=='user'">
					<div class="form">
						<div class="item">
							<div class="title">{{$t('user.userName')}}:</div>
							<div class="text">{{account}}</div>
						</div>
						<div class="item">
							<div class="title">{{$t('user.lv')}}:</div>
							<div class="text">
								<div class="value">
									{{
										type==1?
										$t('level')[0]:
										type==2?
										$t('level')[1]:
										type==4?
										$t('level')[2]:
										type=='DEX'?
										$t('level')[3]:type
									}}
									（{{$t('common.endtime')}}：{{available_time}}）
								</div>
								<!-- <router-link tag="div" class="g_btn btn" to="/renew">{{$t('user.upORren')}}</router-link> -->
								<div class="g_btn btn" @click="tipTo">{{$t('user.upORren')}}</div>
							</div>
						</div>
						<!-- 自动续费 -->
						<div class="help">
							<div class="title">{{$t('user.autoRenew')}}:</div>
							<div class="text">
								<el-switch
                                @change="autoRenewChange"
								active-value="1"
								inactive-value="0"
								v-model="autoState"
								active-color="#E65B1B"
                                inactive-color="#e9e9e9">
								</el-switch>
							</div>
						</div>
						<div class="help">
							<div class="title">{{$t('user.help')}}:</div>
							<div class="text">
								<el-switch
                                @change="helpChange"
								active-value="1"
								inactive-value="0"
								v-model="helpState"
								active-color="#E65B1B"
                                inactive-color="#e9e9e9">
								</el-switch>
							</div>
						</div>
					</div>
				</div>
				
				<div class="row_right" v-show="selectTab=='safe'">
					<div class="form">
						 <!-- v-if="contact_type==1" -->
						<div class="item">
							<div class="title">{{$t('common.telegram')}}:</div>
							<div class="text">
								<div class="value">{{telegram?'@'+telegram:telegram}}</div>
								<span class="g_btn btn" @click="bindTelegram">{{telegram?$t('common.remove'):$t('common.bind')}}</span>
							</div>
						</div>
						<div class="item">
							<div class="title">{{$t('common.email')}}:</div>
							<div class="text">
								<div class="value">{{email}}</div>
								<span class="g_btn btn" @click="showEmail = true">{{email?$t('common.modify'):$t('common.bind')}}</span>
							</div>
						</div>
						<div class="item">
							<div class="title">{{$t('common.pwd')}}:</div>
							<div class="text">
								<div class="value">**************</div>
								<span class="g_btn btn" @click="openPwdPop">{{$t('common.modify')}}</span>
							</div>
						</div>
						<div class="safeLogin">
							<div class="title">{{$t('user.safeLogin')}}:</div>
							<div class="text">
								<el-switch
                                @change="switchChange"
								active-value="1"
								inactive-value="0"
								v-model="safeType"
								active-color="#E65B1B"
                                inactive-color="#e9e9e9">
								</el-switch>
							</div>
						</div>
						<!-- <div class="ipVer">
							<div class="title">
								{{$t('user.ipVer')}}:<span>({{$t('user.ipRecommend')}})</span>
							</div>
							<div class="text">
								<el-switch
                                @change="switchChange2"
								active-value="1"
								inactive-value="0"
								v-model="ipVer"
								active-color="#E65B1B"
                                inactive-color="#e9e9e9">
								</el-switch>
							</div>
						</div> -->
					</div>
				</div>

				<div class="row_lang" v-show="selectTab=='lang'">
					<div class="form">
						<div class="item">
							<div class="title">{{$t('user.language')}}:</div>
							<div class="text">
								<div class="value">{{langList[lang]}}</div>
								<span class="g_btn btn" @click="openLang">{{$t('common.modify')}}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="g_btn btn logout" @click="logOut">{{$t('head.out')}}</div>
			</div>
		
			<!-- 修改手机号 -->
			<!-- <el-dialog :title="$t('user.modifyNum')" :visible.sync="showPhone" :show-close="false" center :before-close="handleCancel">
				<div class="add_form">
					<div class="form">
						<div class="item">
							<div class="title">{{$t('common.newPhone')}}</div>
							<div class="text">
								<input
									class="inp"
									:placeholder="$t('common.input')"
									type="number"
									v-model="form.tel"
								/>
							</div>
						</div>
						<div class="item disable">
							<div class="title">{{$t('common.email')}}</div>
							<div class="text">
								<input
									class="inp"
									:placeholder="$t('common.bind')+$t('common.email')"
									disabled
									v-model="email"
								/>
							</div>
						</div>
						<div class="item">
							<div class="title">
								{{$t('common.verification')}}
							</div>
							<div class="text">
								<div class="inp_code">
									<input
										class="inp"
										:placeholder="$t('common.inputVerification')"
										type="text"
										v-model="form.sms_code"
									/>
									<div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
									<div v-else class="g_btn btn" @click="handleSendCode">
										<template v-if="firstSend">{{$t('common.sendVerification')}}</template>
										<template v-else>{{$t('common.resend')}}</template>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="foot">
						<div class="btn" @click="handleCancel">{{$t('common.cancel')}}</div>
						<div class="btn submit" @click="handleConfirm">{{$t('common.confirm2')}}</div>
					</div>
				</div>
			</el-dialog> -->
			<!-- 修改邮箱 -->
			<el-dialog :title="email?$t('user.changeEmail'):$t('user.bindEmail')" :visible.sync="showEmail" :show-close="false" center :before-close="handleCancel">
				<div class="add_form">
					<div class="form">
						<div class="item">
							<div class="title">{{$t('common.newEmail')}}</div>
							<div class="text">
								<input
									class="inp"
									:placeholder="$t('common.input')"
									v-model="form.email"
								/>
							</div>
						</div>
						<div class="item">
							<div class="title">{{$t('common.verification')}}</div>
							<div class="text">
								<div class="inp_code">
									<input
										class="inp"
										:placeholder="$t('common.inputVerification')"
										type="text"
										v-model="form.sms_code"
									/>
									<div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
									<div v-else class="g_btn btn" @click="handleSendCode('email')">
										<template v-if="firstSend">{{$t('common.sendVerification')}}</template>
										<template v-else>{{$t('common.resend')}}</template>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="foot">
						<div class="btn" @click="handleCancel">{{$t('common.cancel')}}</div>
						<div class="btn submit" @click="editEmail">{{$t('common.confirm2')}}</div>
					</div>
				</div>
			</el-dialog>
			<!-- 修改密码 -->
			<el-dialog :title="$t('user.changePwd')" :visible.sync="showPassw" :show-close="false" center :before-close="handleCancel">
				<div class="add_form">
					<div class="form">
						<div class="item disable">
							<div class="title">{{$t('common.email')}}</div>
							<div class="text">
								<input
									class="inp"
									:placeholder="$t('common.input')"
									disabled
									v-model="email"
								/>
							</div>
						</div>
						<div class="item">
							<div class="title">{{$t('common.verification')}}</div>
							<div class="text">
								<div class="inp_code">
									<input
										class="inp"
										:placeholder="$t('common.inputVerification')"
										type="text"
										v-model="form.sms_code"
									/>
									<div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
									<div v-else class="g_btn btn" @click="handleSendCode">
										<template v-if="firstSend">{{$t('common.sendVerification')}}</template>
										<template v-else>{{$t('common.resend')}}</template>
									</div>
								</div>
							</div>
						</div>
						<div class="item">
							<div class="title">{{$t('common.newPwd')}}</div>
							<div class="text">
								<input
									class="inp"
									:placeholder="$t('common.input')"
									type="password"
									v-model="form.password1"
								/>
							</div>
						</div>
						<div class="item">
							<div class="title">{{$t('common.confirmNewPwd')}}</div>
							<div class="text">
								<input
									class="inp"
									:placeholder="$t('common.input')"
									type="password"
									v-model="form.password2"
								/>
							</div>
						</div>
					</div>
					<div class="foot">
						<div class="btn" @click="handleCancel">{{$t('common.cancel')}}</div>
						<div class="btn submit" @click="handleSubmit">{{$t('common.confirm2')}}</div>
					</div>
				</div>
			</el-dialog>
			<!-- 切换语言 -->
			<el-dialog :title="$t('user.changeLanguage')" :visible.sync="showLang" :show-close="false" center :before-close="handleCancel2">
				<div class="add_form">
					<div class="form">
						<div class="item">
							<div class="title">{{$t('user.language')}}</div>
							<div class="select">
								<el-select v-model="popLang" :placeholder="$t('common.select')">
									<el-option v-for="(item,key,index) in langList" :key="index"
										:label="item"
										:value="key">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>
					<div class="foot">
						<div class="btn" @click="handleCancel2">{{$t('common.cancel')}}</div>
						<div class="btn submit" @click="handleConfirm2">{{$t('common.confirm2')}}</div>
					</div>
				</div>
			</el-dialog>
		
		</template>
		<template v-else-if="isMobile==1">
			<!-- 移动端判定 -->
			<div class="jump_box">
				<div class="row" @click="switchTabMoblie('centerUser')">
					<div class="left">
						<i class="iconfont icon-usercenter"></i>
						{{$t('user.data')}}
					</div>
					<div class="right">
						<i class="iconfont2 icon-right_arrow"></i>
					</div>
				</div>
				<div class="row" @click="switchTabMoblie('centerSafe')">
					<div class="left">
						<i class="iconfont icon-safe"></i>
						{{$t('user.security')}}
					</div>
					<div class="right">
						<i class="iconfont2 icon-right_arrow"></i>
					</div>
				</div>
				<div class="row" @click="switchTabMoblie('centerLang')">
					<div class="left">
						<i class="iconfont icon-lang"></i>
						{{$t('user.language')}}
					</div>
					<div class="right">
						<i class="iconfont2 icon-right_arrow"></i>
					</div>
				</div>
				<div class="row" @click="switchTabMoblie('centerTheme')">
					<div class="left">
						<i v-if="theme!='light'" class="iconfont icon-moon"></i>
						<i v-else class="iconfont2 icon-rijian"></i>
						{{$t('user.model')}}
					</div>
					<div class="right">
						<i class="iconfont2 icon-right_arrow"></i>
					</div>
				</div>
				<div class="row" @click="switchTabMoblie('renew')">
					<div class="left">
						<i class="iconfont icon-check"></i>
						{{$t('aside.renew')}}
					</div>
					<div class="right">
						<i class="iconfont2 icon-right_arrow"></i>
					</div>
				</div>
				<div class="g_btn btn logout" @click="logOut">{{$t('head.out')}}</div>
			</div>
		</template>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'index.styl';
</style>

<script>
	import { Loading } from 'element-ui';
			
	var timer;
	// api
	import {
		// updateTel,
		cancelTelegramBind,
		updatePassword,sendEmail,updateEmail,setSafeLogin,setIPCheck,paySet
	} from '@/api/user'

	import { mapGetters } from 'vuex';
	import langList from '@/assets/js/langList'
	export default ({
		name: 'user',
		data() {
			return {
				// showPhone: false,
            	isUnbinding:false,//解绑是否是点击状态
				showPassw: false,
				showEmail: false,

				codeSending: false,
				timeCount: 60,
				firstSend: true,

				// 此处数据仅用于测试,请自行设置数据名称
				form: {},
				autoState:'1',
				helpState: '1',//是否需要新手引导
				safeType:'0',//安全登录 0 1
				ipVer:'1',//IP验证 0 1

				// 移动端tab切换
				selectTab:'user',
				popLang:'',
				showLang:false,
				
            	langList:langList
			}
		},
		created() {
			this.autoState=this.pay_set
			this.helpState=this.novice
			this.safeType=this.safe_login
			this.ipVer=this.ip_check
			if(this.isMobile!=1){
				this.getInfo();
			}
		},
		computed:{
			...mapGetters(['account','type','available_time',
			// 'tel',
			'telegram',
			'email','safe_login','ip_check','isSmall','lang','isMobile','novice','theme','pay_set']),
			// contact_type(){
			// 	// var isTelegram;
			// 	// if(!this.tel){
			// 	// 	isTelegram=0
			// 	// }else{
			// 	// 	var tel=this.tel.slice(-11)
			// 	// 	var reg =/^0?1[3|4|5|6|7|8][0-9]\d{8}$/;
			// 	// 	isTelegram=reg.test(tel)?0:1
			// 	// }
			// 	var reg =/^\d+$/;
			// 	// 纯数字或者不存在则是手机号 否则是电报
			// 	var isTelegram=(reg.test(this.tel)||!this.tel)?0:1
			// 	return isTelegram;
			// },
		},
		methods: {
			tipTo(){
				// if(this.account=='guanxiny1'){
					this.$router.push({
						path: '/renew',
					})
				// }else{
				// 	// 请您联系商务客服续费！
				// 	this.$message({
				// 		message: this.$t('tip.renewTip'),
				// 		type: 'info',
				// 		center: true,
				// 		customClass: 'shotMsg',
				// 	});
				// }
			},
			// 自动续费开关
			autoRenewChange(){
				paySet({
					account:this.account,
					type:this.autoState,
				}).then(res=>{
					this.getInfo()
					// 修改成功
					this.$message({
						message: this.$t('tip.operationSuc'),
						type: 'success',
						center: true,
						customClass: 'shotMsg',
					});
				}).catch(err=>{
					this.autoState=this.autoState=='1'?'0':'1'
				})
			},
			// 新手引导开关
			helpChange(){
				this.$store.dispatch('user/setNovice',this.helpState)
			},
			// 获取详情
			getInfo(){
				const loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				this.$store.dispatch('user/getInfo').then(res=>{
					// console.log(res)
					this.autoState=this.pay_set
					this.safeType=this.safe_login
					this.ipVer=this.ip_check
					loading.close();
				}).catch(err=>{
					loading.close();
				})
			},
			// 发送验证码
			handleSendCode(type) {
				if(!this.codeSending){
					// 发送验证码
					// sendCode({
					// 	account:this.account,
					// 	tel:this.tel,
					// 	type:1,
					
					var email;
					if(type=='email'){
						// 修改邮箱
            			var emailReg=/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
						// /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
						if(!this.form.email){
							this.$message({
								message: this.$t('tip.emailEmpty')+'！',
								type: 'error',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
							return
						}else if(!emailReg.test(this.form.email) ){
							this.$message({
								message: this.$t('tip.emailVer')+'！',
								type: 'error',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
							return
						}else if(this.form.email==this.email){
							this.$message({
								message: this.$t('tip.emailRepeat'),
								type: 'error',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
							return
						}else{
							email=this.form.email
							// 发送验证码
							this.timeCount = 60;
							this.codeSending = true;
							let that = this;
							this.firstSend = false;
							clearInterval(timer);
							timer = setInterval(() => {
								if(this.timeCount >= 1) {
									that.timeCount -= 1;
								} else {
									clearInterval(timer);
									this.codeSending = false;
								}
							}, 1000);

							sendEmail({
								account:this.account,
								email,
								type:2,
							})
						}
					}else{
						// 修改手机、密码
						email=this.email
						// 发送验证码
						this.timeCount = 60;
						this.codeSending = true;
						let that = this;
						this.firstSend = false;
						clearInterval(timer);
						timer = setInterval(() => {
							if(this.timeCount >= 1) {
								that.timeCount -= 1;
							} else {
								clearInterval(timer);
								this.codeSending = false;
							}
						}, 1000);
					
						sendEmail({
							account:this.account,
							email,
							type:1,
						})
					}
				}
			},
			
			handleCancel() {
				// this.showPhone = false;
				this.showEmail = false;
				this.showPassw = false;
				this.form = {};
				
				clearInterval(timer);
				this.codeSending = false;
				this.firstSend = true;
			},
			// 打开修改手机弹窗
			// openPhonePop(){
			// 	if(!this.email){
			// 		this.$confirm(this.$t('tip.notBindEmail'), this.$t('tip.tips'), {
			// 			confirmButtonText: this.$t('common.toBind'),
			// 			cancelButtonText: this.$t('common.cancel'),
			// 			center: true,
			// 		}).then(() => {
			// 			this.showEmail=true
			// 		}).catch(() => {
			// 			// 取消
			// 		});
			// 	}else{
			// 		this.showPhone = true;
			// 	}
			// },
			// 修改手机号提交
			// handleConfirm() {
            // 	var phoneReg=/^1[3456789]\d{9}$/;
			// 	if(!this.form.tel){
			// 		this.$message({
			// 			message: this.$t('tip.phoneEmpty')+'！',
			// 			type: 'error',
			// 			center: true,
			// 			offset: 100,
			// 			customClass: 'shotMsg',
			// 		});
			// 	}else if(!phoneReg.test(this.form.tel) ){
			// 		this.$message({
			// 			message: this.$t('tip.phoneVer')+'！',
			// 			type: 'error',
			// 			center: true,
			// 			offset: 100,
			// 			customClass: 'shotMsg',
			// 		});
			// 	}else if(!this.form.sms_code){
			// 		this.$message({
			// 			message: this.$t('tip.codeEmpty')+'！',
			// 			type: 'error',
			// 			center: true,
			// 			offset: 100,
			// 			customClass: 'shotMsg',
			// 		});
			// 	}else{
			// 		updateTel({
			// 			account:this.account,
			// 			tel:this.form.tel,
			// 			sms_code:this.form.sms_code,
			// 			type:!!this.tel? 1:0,
			// 		}).then(res=>{
			// 			// console.log(res,7777)
			// 			if(res.data==1){
			// 				this.getInfo()
			// 				// 修改成功
			// 				this.$message({
			// 					message: this.$t('tip.editSuc'),
			// 					type: 'success',
			// 					center: true,
			// 					offset: 100,
			// 					customClass: 'shotMsg',
			// 				});
			// 				this.showPhone = false;
			// 				this.form = {};
							
			// 				clearInterval(timer);
			// 				this.codeSending = false;
			// 				this.firstSend = true;
			// 			}else{
			// 				// 修改失败
			// 				this.$message({
			// 					message: this.$t('tip.editErr'),
			// 					type: 'error',
			// 					center: true,
			// 					offset: 100,
			// 					customClass: 'shotMsg',
			// 				});
			// 			}
			// 		})
			// 	}
			// },
			// 绑定电报
			bindTelegram(){
				if(this.telegram){
            		if(!this.isUnbinding){
                		this.isUnbinding=true
						// 解除绑定
						cancelTelegramBind({
							account:this.account
						}).then(res=>{
							if(res==1){
								this.$message({
									message: this.$t('tip.remove'),
									type: 'success',
									center: true,
									customClass: 'shotMsg',
								});
								this.getInfo();
							}
							this.isUnbinding=false
						}).catch(err=>{
							this.isUnbinding=false
						})
					}
				}else{
					// window.open('https://t.me/light_helper_bot')
					window.location.href='https://t.me/light_helper_bot'
				}
			},
			// 修改邮箱提交
			editEmail(){
            	var emailReg=/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
				// /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
				if(!this.form.email){
					this.$message({
						message: this.$t('tip.emailEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!emailReg.test(this.form.email) ){
					this.$message({
						message: this.$t('tip.emailVer')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.form.sms_code){
					this.$message({
						message: this.$t('tip.codeEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else{
					updateEmail({
						account:this.account,
						email:this.form.email,
						sms_code:this.form.sms_code,
						type:!!this.email? 1:0,
					}).then(res=>{
						if(res==1){
							this.getInfo()
							// 修改成功
							this.$message({
								message: this.$t('tip.editSuc'),
								type: 'success',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
							this.showEmail = false;
							this.form = {};
							
							clearInterval(timer);
							this.codeSending = false;
							this.firstSend = true;
						}else{
							// 修改失败
							this.$message({
								message: this.$t('tip.editErr'),
								type: 'error',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
						}
					})
				}
			},
			openPwdPop(){
				if(!this.email){
					this.$confirm(this.$t('tip.notBindEmail'), this.$t('tip.tips'), {
						confirmButtonText: this.$t('common.toBind'),
						cancelButtonText: this.$t('common.cancel'),
						center: true,
					}).then(() => {
						this.showEmail=true
					}).catch(() => {
						// 取消
					});
				}else{
					this.showPassw = true
				}
			},
			// 修改密码提交
			handleSubmit() {
            	// var phoneReg=/^1[3456789]\d{9}$/;
            	var reg = /[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/;
            	var emailReg=/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
				// /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
				if(!this.email){
					this.$message({
						message: this.$t('tip.emailEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!emailReg.test(this.email) ){
					this.$message({
						message: this.$t('tip.emailVer')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.form.sms_code){
					this.$message({
						message: this.$t('tip.codeEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.form.password1){
					this.$message({
						message: this.$t('tip.pwdEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!reg.test(this.form.password1) || this.form.password1.length < 6){
					Message({
						message: this.$t('tip.pwdVer')+'！',
						type: 'error',
						duration: 3 * 1000
					});
				}else if(this.form.password1!=this.form.password2){
					this.$message({
						message: this.$t('tip.pwd2')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else{
					updatePassword({
						account:this.account,
						password:this.form.password2,
						sms_code:this.form.sms_code,
						type:1,
					}).then(res=>{
						if(res==1){
							// 修改成功
							this.$message({
								message: this.$t('tip.editSuc'),
								type: 'success',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
							
							this.showPassw = false;
							this.form = {};
							
							clearInterval(timer);
							this.codeSending = false;
							this.firstSend = true;
						}else{
							// 修改失败
							this.$message({
								message: this.$t('tip.editErr'),
								type: 'error',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
						}
					})
				}
			},
			// 安全登录开关
			switchChange(){
				setSafeLogin({
					account:this.account,
					type:this.safeType,
				}).then(res=>{
					this.getInfo()
					// 修改成功
					this.$message({
						message: this.$t('tip.operationSuc'),
						type: 'success',
						center: true,
						customClass: 'shotMsg',
					});
				}).catch(err=>{
					this.safeType=this.safeType=='1'?'0':'1'
				})
			},
			// ip验证开关
			switchChange2(){
				if(this.ipVer==0){
					this.$confirm(this.$t('tip.ipClose')+'<div style="color:#E65B1B">'+this.$t('tip.ipCloseTip')+'</div>', this.$t('tip.tips'), {
						confirmButtonText: this.$t('common.confirm'),
						cancelButtonText: this.$t('common.cancel'),
						center: true,
						showClose: false,
						customClass: 'deleteOk',
               			dangerouslyUseHTMLString: true
					}).then(() => {
						setIPCheck({
							account:this.account,
							type:this.ipVer,
						}).then(res=>{
							this.getInfo()
							// 修改成功
							this.$message({
								message: this.$t('tip.operationSuc'),
								type: 'success',
								center: true,
								customClass: 'shotMsg',
							});
						}).catch(err=>{
							this.ipVer=this.ipVer=='1'?'0':'1'
						})
					}).catch(err=>{
						this.ipVer=this.ipVer=='1'?'0':'1'
					})
				}else{
					setIPCheck({
						account:this.account,
						type:this.ipVer,
					}).then(res=>{
						this.getInfo()
						// 修改成功
						this.$message({
							message: this.$t('tip.operationSuc'),
							type: 'success',
							center: true,
							customClass: 'shotMsg',
						});
					}).catch(err=>{
						this.ipVer=this.ipVer=='1'?'0':'1'
					})
				}
			},

			// 移动端适配tab切换
			switchTab(tab){
				this.selectTab=tab
			},
			// 移动端判定tab切换
			switchTabMoblie(tab){
				this.$router.push({
					path:"/"+tab
				})
			},
			logOut() {
				// this.$alert('退出登录');
				this.$confirm(this.$t('head.outTip'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.cancel'),
					center: true,
				}).then(() => {
					// 退出登录
					this.$store.dispatch('user/logout').then(res=>{
						this.$router.push({
						    path:'/login'
						})
					})
				}).catch(() => {
					// 取消
				});
			},
			openLang(){
				this.popLang=this.lang
				this.showLang = true
			},
			// 关闭语言切换弹窗
			handleCancel2(){
				this.showLang=false;
			},
			handleConfirm2(){
				this.showLang=false;
				this.langChange(this.popLang)
			},
			langChange(value) {
				this.$i18n.locale = value;
				// this.lang = value;
				// localStorage.setItem('lang', value);
				this.$store.dispatch('app/setLang',value)
			},
		},
		beforeDestroy() {
			if(timer){
				clearInterval(timer)
				timer=''
			}
		}
	})
</script>
